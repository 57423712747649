// MessageList.js
import React, { useEffect, useRef } from 'react';
import ChatMessage from './ChatMessage';
import '../chat.css';

  // Create a ref for the message list

const MessageList = ({ messages }) => {
    const messagesEndRef = useRef(null);



    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, [messages]);



//   return (
//     <div className="message-list">
//       {messages.map((msg, index) => (
//         <ChatMessage key={index} message={msg.text} isUser={msg.isUser} />
//       ))}
//     </div>
//   );


  return (
    <div className="message-list">
    
      {messages.map((msg, index) => (
        <ChatMessage key={index} message={msg.text} isUser={msg.isUser} />
      ))}
      {/* Invisible element at the end of the messages */}
      <div ref={messagesEndRef} />
    </div>
  );

};

export default MessageList;
