import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../chat.css";

const LoginButton = () => {
  const { loginWithRedirect, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>
  } else {
    return (
      <button className="login-button" onClick={() => loginWithRedirect()}>
        Log In
      </button>
    );
  }
};

export default LoginButton;