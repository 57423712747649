// ChatInput.js
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import '../chat.css';

const ChatInput = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');

  

  const handleSendMessage = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
    }
  };

  return (
    <div className="input-container">
      <input 
        className="input-field"
        type="text"
        placeholder="Type a message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
      />
      <Button className="send-button" onClick={handleSendMessage}>Send</Button>
    </div>
  );
};

export default ChatInput;
